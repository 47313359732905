import list from 'modules/clusterEbox/list/clusterEboxListReducers';
import form from 'modules/clusterEbox/form/clusterEboxFormReducers';
import view from 'modules/clusterEbox/view/clusterEboxViewReducers';
import destroy from 'modules/clusterEbox/destroy/clusterEboxDestroyReducers';
import importerReducer from 'modules/clusterEbox/importer/clusterEboxImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

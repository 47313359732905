import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import BooleanField from 'modules/shared/fields/booleanField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import FilesField from 'modules/shared/fields/filesField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';

function label(name) {
  return i18n(`entities.payment.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.payment.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  value: new StringField(
    'value',
    label('value'),
    {
      max: 4,
    },
  ),
  gateway: new StringField(
    'gateway',
    label('gateway'),
    {
      max: 16,
    },
  ),
  orderId: new RelationToOneField(
    'order',
    label('orderId'),
    {
      required: true,
    },
  ),
  company: new RelationToOneField(
    'company',
    label('company'),
    {
      required: true,
    },
  ),
  orderEbox: new RelationToOneField('order.ebox', label('ebox'), {}),
  ebox: new RelationToOneField('ebox', label('ebox'), {}),
  cluster: new RelationToOneField('cluster', label('cluster'), {}),
  orderCluster: new RelationToOneField('order.cluster', label('cluster'), {}),
  trackingCode: new StringField(
    'trackingCode',
    label('trackingCode'),{}
  ),
  compartimentNumberSize: new RelationToOneField(
    'order.compartiment',
    label('compartiment'),
    {}
  ),
  compartimentNumber: new StringField(
    'compartimentNumber',
    label('compartimentNumber'),
    {}
  ),
  compartimentSize: new StringField(
    'compartimentSize',
    label('compartimentSize'),
    {}
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  overdueDate: new DateTimeField(
    'overdueDate',
    label('overdueDate'),
  ),
  overdueDateRange: new DateTimeRangeField(
    'overdueDateRange',
    label('overdueDate'),
  ),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'PENDING',
      label: enumeratorLabel('status', 'PENDING'),
    },
    {
      id: 'APPROVED',
      label: enumeratorLabel('status', 'APPROVED'),
    },
    {
      id: 'DECLINED',
      label: enumeratorLabel('status', 'DECLINED'),
    }
  ]),  
};

export default {
  fields,
};

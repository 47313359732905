import actions from './eboxGenerateTokenActions';

const initialData = {
  id: '',
  tokenData: ''
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.GENERATE_SUCCESS) {
    console.log(payload);
    return {
      ...state,
      tokenData: payload
    }
  }

  return state;
}
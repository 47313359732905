import model from 'modules/customer/customerModel';

const { fields } = model;

export default [
  fields.name,
  fields.cellphone,
  fields.email,
  fields.documentNumber,
  fields.pcd,
  fields.enable,
];

import list from 'modules/terminalUiVersion/list/versionListReducers';
import form from 'modules/terminalUiVersion/form/versionFormReducers';
import view from 'modules/terminalUiVersion/view/versionViewReducers';
import destroy from 'modules/terminalUiVersion/destroy/versionDestroyReducers';
import importerReducer from 'modules/terminalUiVersion/importer/versionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
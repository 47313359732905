import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/buildingLevel2/importer/buildingLevel2ImporterSelectors';
import BuildingLevel2Service from 'modules/buildingLevel2/buildingLevel2Service';
import fields from 'modules/buildingLevel2/importer/buildingLevel2ImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'BUILDINGLEVEL2_IMPORTER',
  selectors,
  BuildingLevel2Service.import,
  fields,
  i18n('entities.buildingLevel2.importer.fileName'),
);

import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/terminalUiRelease/importer/releaseImporterSelectors';
import ReleaseService from 'modules/terminalUiRelease/releaseService';
import fields from 'modules/terminalUiRelease/importer/releaseImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'RELEASE_IMPORTER',
  selectors,
  ReleaseService.import,
  fields,
  i18n('entities.release.importer.fileName'),
);

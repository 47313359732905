import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/ccm/importer/ccmImporterSelectors';
import CcmService from 'modules/ccm/ccmService';
import fields from 'modules/ccm/importer/ccmImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'CCM_IMPORTER',
  selectors,
  CcmService.import,
  fields,
  i18n('entities.ccm.importer.fileName'),
);

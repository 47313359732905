import authAxios from 'modules/shared/axios/authAxios';

export default class InboundService {

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/inbound`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/inbound/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/inbound`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/inbound/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

}

import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import FilesField from 'modules/shared/fields/filesField';
import ImagesField from 'modules/shared/fields/imagesField';
import BooleanField from 'modules/shared/fields/booleanField';
import StringArrayField from 'modules/shared/fields/stringArrayField';
import * as yup from 'yup';

function label(name) {
  return i18n(`entities.company.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.company.enumerators.${name}.${value}`);
}

class OrderTypesField extends StringArrayField {
  constructor(name, label, config) {
    super(name, label, config);

    this.options = [
      { id: 'DELIVERY', label: 'DELIVERY' },
      { id: 'RETURN', label: 'RETURN' },
      { id: 'STORAGE', label: 'STORAGE' },
      { id: 'SEND', label: 'SEND' },
      { id: 'SMARTDROP', label: 'SMARTDROP' }
    ]
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((values) =>
        values
          ? values
            .map((value) => value)
            .join(' ')
          : null,
      );
  }
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  logo: new ImagesField('logo', label('logo'), 'company/logo', {}),
  type: new EnumeratorField('type', label('type'), [
    { id: 'shipper', label: enumeratorLabel('type', 'shipper') },
    { id: 'carrier', label: enumeratorLabel('type', 'carrier') },
    { id: 'building', label: enumeratorLabel('type', 'building') },
  ], {}),
  externalId: new StringField('externalId', label('externalId'), {}),
  allowCollectExpiredOrder: new BooleanField('allowCollectExpiredOrder', label('allowCollectExpiredOrder')),
  attachments: new FilesField('attachments', label('attachments'), 'company/attachments', {}),
  parent: new RelationToOneField('parent', label('parent'), {}),
  building: new RelationToManyField('building', label('building'), {}),
  group: new RelationToManyField('group', label('group'), {}),
  distributionLogistic: new RelationToManyField('distributionLogistic', label('distributionLogistic'), {}),

  companySettings: {
    orderTypes: new OrderTypesField('orderTypes', label('orderTypes'), {
      required: false,
    }),
    smsGateway: new EnumeratorField('smsGateway', label('smsGateway'), [
      { id: 'AWS', label: 'AWS' },
      { id: 'Sinch', label: 'Sinch' }
    ], {}),
  },
  maxDaysOverdueDateDelivery: new StringField('maxDaysOverdueDateDelivery', label('overdueData'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export default {
  fields,
};

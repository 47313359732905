import list from 'modules/building/list/buildingListReducers';
import form from 'modules/building/form/buildingFormReducers';
import view from 'modules/building/view/buildingViewReducers';
import destroy from 'modules/building/destroy/buildingDestroyReducers';
import importerReducer from 'modules/building/importer/buildingImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

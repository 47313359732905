import model from 'modules/terminalUiRelease/releaseModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.release,
  fields.createdAt,
  fields.updatedAt
];

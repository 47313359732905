import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/terminalUiVersion/importer/versionImporterSelectors';
import VersionService from 'modules/terminalUiVersion/versionService';
import fields from 'modules/terminalUiVersion/importer/versionImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'VERSION_IMPORTER',
  selectors,
  VersionService.import,
  fields,
  i18n('entities.version.importer.fileName'),
);

import OrderService from 'modules/order/orderService';
import Errors from 'modules/shared/error/errors';
import { i18n } from 'i18n';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';

const prefix =
  'ORDER_GENERATE_TAG';

const actions = {
  GENERATE_TAG_STARTED: `${prefix}GENERATE_TAG_STARTED`,
  GENERATE_TAG_SUCCESS: `${prefix}GENERATE_TAG_SUCCESS`,
  GENERATE_TAG_ERROR: `${prefix}GENERATE_TAG_ERROR`,

  GENERATE_TAG_ALL_STARTED: `${prefix}GENERATE_TAG_ALL_STARTED`,
  GENERATE_TAG_ALL_SUCCESS: `${prefix}GENERATE_TAG_ALL_SUCCESS`,
  GENERATE_TAG_ALL_ERROR: `${prefix}GENERATE_TAG_ALL_ERROR`,

  doGenerateTag: (orders) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GENERATE_TAG_STARTED,
      });

      orders = orders.reduce((acc, data) => {
        const { ebox, trackingCode, referenceNumber, toCustomer, company } = data;
        const eBoxAddress = (ebox && ebox.street ? `${ebox.street},${ebox.number}` : null) || (toCustomer && toCustomer.address ? `${toCustomer.address.street},${toCustomer.address.number}` : null) || null;
        const eBoxZipCode = (ebox && ebox.postalCode ? ebox.postalCode : null) || (toCustomer && toCustomer.address ? toCustomer.address.postalCode : null) || null;
        const eBoxCode = ebox && ebox.code && ebox.name ? `${ebox.code} - ${ebox.name}` : null;
        acc.push({
          name: toCustomer ? toCustomer.name : 'N/A',
          barCode: trackingCode,
          eBoxAddress,
          eBoxZipCode,
          eBoxCode,
          createTime: new Date().getTime(),
          logisticsCompany: company.name || "Clique Retire",
          orderNumber: referenceNumber || null,
        });
        return acc;
      }, []);

      const response = await OrderService.generateTag(orders);

      var link = document.createElement('a');
      link.innerHTML = 'Download PDF file';
      link.download = `print-tags-${new Date().toLocaleString()}.pdf`;
      link.href = 'data:application/octet-stream;base64,' + response.body;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch({
        type: actions.GENERATE_TAG_SUCCESS,
      });

      Message.success(i18n('entities.order.generateTag.success'));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.GENERATE_TAG_ERROR,
      });
    }
  },

}

export default actions;
import authAxios from 'modules/shared/axios/authAxios';
import { i18n } from 'i18n';
export default class CompanyService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/company/${id}`,
      body,
    );

    return response.data;
  }

  static async listCompanySettingsAutocomplete(type, query) {
    const enumeratorLabel = (name, value) => {
      return i18n(
        `entities.order.enumerators.${name}.${value}`,
      );
    }

    const params = {
      query
    };

    const response = await authAxios.get(
      `/company-setting/${type}`,
      {
        params,
      },
    );

    if (response.data.length !== 0) {
      response.data.map((item) => {
        item.label = enumeratorLabel('type', item.label)
      })
    }

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/company`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/company`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/company/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/company/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/company`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/company/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}

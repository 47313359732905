import model from 'modules/cluster/clusterModel';

const { fields } = model;

export default [
  fields.company,
  fields.sequence,
  fields.name,
  fields.clusterEbox,
  fields.enable,
];

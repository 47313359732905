import model from 'modules/ebox/eboxModel';

const { fields } = model;

export default [
  fields.name,
  fields.code,
  fields.images,
  fields.enable,
  fields.status,
  fields.street,
  fields.number,
  fields.complement,
  fields.neighborhood,
  fields.city,
  fields.state,
  fields.postalCode,
  fields.warehouse,
];

import EboxService from 'modules/ebox/eboxService';
import Errors from 'modules/shared/error/errors';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';
import { i18n } from 'i18n';

const prefix = 'EBOX_VIEW';

const actions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,
  METRICS_FIND: `${prefix}_METRICS_FIND`,
  METRICS_SUCCESS: `${prefix}_METRICS_SUCCESS`,
  METRICS_ERROR: `${prefix}_METRICS_ERROR`,
  COMMAND_STARTED: `${prefix}_COMMAND_STARTED`,
  COMMAND_SUCCESS: `${prefix}_COMMAND_SUCCESS`,
  COMMAND_ERROR: `${prefix}_COMMAND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: actions.FIND_STARTED,
      });

      const record = await EboxService.find(id);

      dispatch({
        type: actions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });

      getHistory().push('/ebox');
    }
  },

  doMetrics: (id) => async (dispatch) => {
    try {
      dispatch({
        type: actions.METRICS_FIND,
      });

      const record = await EboxService.getMetrics(id);

      dispatch({
        type: actions.METRICS_SUCCESS,
        payload: record,
      });
    } catch (err) {
      Errors.handle(err);

      dispatch({
        type: actions.METRICS_ERROR,
      });

      getHistory().push('/ebox');
    }
  },

  doSendCommand: (payload) => async (dispatch) => {
    try {
      dispatch({
        type: actions.COMMAND_STARTED,
      });

      await EboxService.terminalCommander(payload);

      dispatch({
        type: actions.COMMAND_SUCCESS
      });

      Message.success(i18n(`entities.ebox.settings[${payload.type_message}].success`));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.COMMAND_ERROR,
      });

      Message.error(i18n(`entities.ebox.settings[${payload.type_message}].error`));
    }
  }
};

export default actions;

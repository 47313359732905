import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';

function label(name) {
  return i18n(`entities.version.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('file.name', label('name'), {}),
  versionNumber: new StringField('versionNumber', label('versionNumber'),  {}),
  osApplication: new StringField('osApplication', label('osApplication'),  {}),
  description: new StringField('description', label('description'),  {
      required: true,
    }
  ),
  identification: new StringField('identification', label('identification'),  {
      required: true,
    }
  ),
  company: new RelationToOneField(
    'company',
    label('company'),
    {
      required: true,
    },
  ),
  keyS3: new IdField('keyS3', 'keyS3'),
  file : new IdField('file', 'file'),
  download: new StringField('file', label('download'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export default {
  fields,
};

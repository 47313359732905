import list from 'modules/ccm/list/ccmListReducers';
import form from 'modules/ccm/form/ccmFormReducers';
import view from 'modules/ccm/view/ccmViewReducers';
import destroy from 'modules/ccm/destroy/ccmDestroyReducers';
import importerReducer from 'modules/ccm/importer/ccmImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

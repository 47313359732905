import authAxios from 'modules/shared/axios/authAxios';

export default class PaymentService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/payment/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/payment`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/payment`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/payment/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/payment/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/payment`, {
      params,
    });

    response.data.rows.map(
      (row) => {
        row.ebox = row.order.ebox;
        row.cluster = row.order.cluster;
        row.compartiment = row.order.compartiment;
        row.trackingCode = row.order.trackingCode;
        row.compartimentSize = row.order.compartiment ? row.order.compartiment.size : null;
        row.compartimentNumber = row.order.compartiment ? row.order.compartiment.number : null;
      }
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/payment/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}

import model from 'modules/clusterEbox/clusterEboxModel';

const { fields } = model;

export default [
  fields.id,
  fields.cluster,
  fields.ebox,
  fields.clusterPosition,
  fields.sequenceCompartiments,
  fields.enable,
  fields.createdAt,
  fields.updatedAt
];

import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/cluster/importer/clusterImporterSelectors';
import ClusterService from 'modules/cluster/clusterService';
import fields from 'modules/cluster/importer/clusterImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'CLUSTER_IMPORTER',
  selectors,
  ClusterService.import,
  fields,
  i18n('entities.cluster.importer.fileName'),
);

import list from 'modules/group/list/groupListReducers';
import form from 'modules/group/form/groupFormReducers';
import view from 'modules/group/view/groupViewReducers';
import destroy from 'modules/group/destroy/groupDestroyReducers';
import importerReducer from 'modules/group/importer/groupImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

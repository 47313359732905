import model from 'modules/compartiment/compartimentModel';

const { fields } = model;

export default [
  fields.number,
  fields.boardNumber,
  fields.numberInColumn,
  fields.comNumber,
  fields.size,
  fields.ebox,
  fields.status,
  fields.width,
  fields.height,
  fields.depth,
  fields.enable,
  fields.usageType
];

import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import StringArrayField from 'modules/shared/fields/stringArrayField';
import Permissions from 'security/permissions';
import * as yup from 'yup';
import RelationToManyField from 'modules/shared/fields/relationToManyField';

function label(name) {
  return i18n(`entities.role.fields.${name}`);
}

class PermissionsField extends StringArrayField {
  constructor(name, label, config) {
    super(name, label, config);

    this.options = Permissions.selectOptions;
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((values) =>
        values
          ? values
            .map((value) => value)
            .join(' ')
          : null,
      );
  }
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  description: new StringField('description', label('description'), {}),
  permissionsRequired: new PermissionsField('permissions', label('permissions'), {
    required: true,
  }),
  permissions: new PermissionsField('permissions', label('permissions')),
  company: new RelationToOneField('company', label('company'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export default {
  fields,
};

import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/qrcodeTemplate/importer/qrcodeTemplateImporterSelectors';
import QrcodeTemplateService from 'modules/qrcodeTemplate/qrcodeTemplateService';
import fields from 'modules/qrcodeTemplate/importer/qrcodeTemplateImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'QRCODETEMPLATE__IMPORTER',
  selectors,
  QrcodeTemplateService.import,
  fields,
  i18n('entities.qrcodeTemplate.importer.fileName'),
);

import list from 'modules/compartimentLayout/list/compartimentLayoutListReducers';
import form from 'modules/compartimentLayout/form/compartimentLayoutFormReducers';
import view from 'modules/compartimentLayout/view/compartimentLayoutViewReducers';
import destroy from 'modules/compartimentLayout/destroy/compartimentLayoutDestroyReducers';
import importerReducer from 'modules/compartimentLayout/importer/compartimentLayoutImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

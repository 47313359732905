import actions from 'modules/ebox/view/eboxViewActions';

const initialData = {
  loading: false,
  record: null,
  metrics: {
    compartiments: [],
    orders: []
  }
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      record: null,
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      record: payload,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      record: null,
      loading: false,
    };
  }

  if (type === actions.METRICS_FIND) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.METRICS_SUCCESS) {
    return {
      ...state,
      loading: false,
      metrics: payload,
    };
  }

  if (type === actions.METRICS_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.COMMAND_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.COMMAND_SUCCESS || type === actions.COMMAND_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
};

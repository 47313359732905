import actions from 'modules/order/packing/orderPackingActions';

const INITIAL_PAGE_SIZE = 25;

const initialData = [{
  key: null,
  step: null,
  rows: [],
  count: 0,
  loading: false,
  filter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  selectedKeys: [],
  selectedRows: [],
  metrics: []
}];

const appendData = (state, key, step, data) => {
  const findKey = state.find(e => (e.key === key && e.step === step) || (e.key === null && e.step === null));
  if (findKey) {
    return state.map(e => {
      if ((e.key === key && e.step === step) || (e.key === null && e.step === null)) {
        return {
          ...e,
          key,
          step,
          ...data
        };
      }
      return e;
    });
  }
};

export default (state = initialData, { type, payload, key, step } = {}) => {
  if (type === actions.RESETED) {
    return initialData;
  }

  if (type === actions.SELECTEDS_CHANGED) {
    return appendData(state, key, step, {
      selectedKeys: payload.selectedRowKeys || [],
      selectedRows: payload.selectedRows || [],
    });
  }

  if (type === actions.CHANGE_COMPARTIMENT_SIZE) {

    const findKey = state.find(e => (e.key === key && e.step === step) || (e.key === null && e.step === null));

    return appendData(state, key, step, {
      rows: findKey.rows.map(e => e.id === payload.record.id ? { ...e, compartimentSize: payload.value, hasChange: true } : e)
    });
  }

  if (type === actions.ADD_EBOX) {
    const newState = state.filter(e => e.key !== null && e.step !== null);
    newState.push({
      ...initialData[0],
      key,
      step
    })
    return newState;
  }

  if (type === actions.PAGINATION_CHANGED) {
    return appendData(state, key, step, {
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    });
  }

  if (type === actions.SORTER_CHANGED) {
    return appendData(state, key, step, {
      sorter: payload || {},
    });
  }

  if (type === actions.FETCH_STARTED) {
    const existingItem = state.find(e => e.key === key && e.step === step);

    const { selectedKeys, selectedRows } = existingItem;

    const _state = appendData(state, key, step, {
      loading: true,
      selectedKeys: selectedKeys || [],
      selectedRows: selectedRows || [],
      filter: payload ? payload.filter : {},
      pagination: existingItem && payload && payload.keepPagination
        ? existingItem.pagination
        : {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
    });
    return _state;
  }

  if (type === actions.FETCH_SUCCESS) {
    return appendData(state, key, step, {
      loading: false,
      rows: payload.rows,
      count: payload.count
    });
  }

  if (type === actions.FETCH_ERROR) {
    return appendData(state, key, step, {
      loading: false,
      rows: [],
      count: 0
    });
  }
  if (type === actions.METRICS_FIND) {
    return appendData(state, key, step, {
      loading: true,
    });
  }

  if (type === actions.METRICS_SUCCESS) {
    return appendData(state, key, step, {
      loading: false,
      metrics: payload
    });
  }

  if (type === actions.METRICS_ERROR) {
    return appendData(state, key, step, {
      loading: false
    });
  }

  return state;
};

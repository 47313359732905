import model from 'modules/terminalUiVersion/versionModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.version,
  fields.createdAt,
  fields.updatedAt
];

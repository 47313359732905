import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';

function label(name) {
  return i18n(`entities.building.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  company: new RelationToOneField(
    'company',
    label('company'),
    {},
  ),
  level1: new RelationToManyField(
    'level1',
    label('level1'),
    {},
  ),
  lvl1: new StringField(
    'level1',
    label('level1'),
    {},
  ),
  level2: new StringField('level2', label('level2'), {}),
  externalId: new StringField(
    'externalId',
    label('externalId'),
    {},
  ),
  buildingId: new StringField(
    'buildingId',
    label('buildingId'),
    {},
  ),
  customerName: new StringField(
    'customerName',
    label('customer.name'),
    {},
  ),
  customerPhone: new StringField(
    'customerPhone',
    label('customer.phone'),
    {},
  ),
  customerEmail: new StringField(
    'customerEmail',
    label('customer.email'),
    {},
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export default {
  fields,
};

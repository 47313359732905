import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import BooleanField from 'modules/shared/fields/booleanField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import DecimalField from 'modules/shared/fields/decimalField';

function label(name) {
  return i18n(`entities.eboxModule.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  code: new StringField('code', label('code'), {}),
  name: new StringField('name', label('name'), {}),
  eboxModuleCompartiment: new RelationToManyField(
    'eboxModuleCompartiment',
    label('eboxModuleCompartiment'),
    {},
  ),
  defaultBoardNumber: new DecimalField('defaultBoardNumber', label('defaultBoardNumber'), {}),
  enable: new BooleanField('enable', label('enable')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export default {
  fields,
};

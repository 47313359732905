import model from 'modules/building/buildingModel';

const { fields } = model;

export default [
  fields.name,
  fields.buildingId,
  fields.lvl1,
  fields.level2,
  fields.company,
  fields.customerName,
  fields.customerPhone,
  fields.customerEmail
];

import list from 'modules/eboxModuleCompartiment/list/eboxModuleCompartimentListReducers';
import form from 'modules/eboxModuleCompartiment/form/eboxModuleCompartimentFormReducers';
import view from 'modules/eboxModuleCompartiment/view/eboxModuleCompartimentViewReducers';
import destroy from 'modules/eboxModuleCompartiment/destroy/eboxModuleCompartimentDestroyReducers';
import importerReducer from 'modules/eboxModuleCompartiment/importer/eboxModuleCompartimentImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

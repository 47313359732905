import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import StringField from 'modules/shared/fields/stringField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import BooleanField from 'modules/shared/fields/booleanField';

function label(name) {
  return i18n(`entities.cluster.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.cluster.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  company: new RelationToOneField('company', label('company'), {}),
  sequence: new EnumeratorField('sequence', label('sequence'), [
    { id: 'A-Z', label: enumeratorLabel('sequence', 'A-Z') },
    { id: '1-9999', label: enumeratorLabel('sequence', '1-9999') }
  ]),
  name: new StringField('name', label('name'), {}),
  clusterEbox: new RelationToManyField(
    'clusterEbox',
    label('clusterEbox'),
    {},
  ),
  enable: new BooleanField('enable', label('enable')),
  operationType: new EnumeratorField('operationType', label('operationType'), [
    { id: 'RANGE', label: enumeratorLabel('operationType', 'RANGE') },
    { id: 'HOUR', label: enumeratorLabel('operationType', 'HOUR') }
  ]),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export default {
  fields,
};

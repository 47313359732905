import OrderService from 'modules/order/orderService';
import Errors from 'modules/shared/error/errors';
import { getHistory } from 'modules/store';
import { i18n } from 'i18n';
import Message from 'view/shared/message';
const prefix = 'ORDER_VIEW';

const actions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  SEND_NOTIFICATION_STARTED: `${prefix}_SEND_NOTIFICATION_STARTED`,
  SEND_NOTIFICATION_SUCCESS: `${prefix}_SEND_NOTIFICATION_SUCCESS`,
  SEND_NOTIFICATION_ERROR: `${prefix}_SEND_NOTIFICATION_ERROR`,

  OPEN_PORT_STARTED: `${prefix}_OPEN_PORT_STARTED`,
  OPEN_PORT_SUCCESS: `${prefix}_OPEN_PORT_SUCCESS`,
  OPEN_PORT_ERROR: `${prefix}_OPEN_PORT_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: actions.FIND_STARTED,
      });

      const record = await OrderService.find(id);

      dispatch({
        type: actions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });

      getHistory().push('/order');
    }
  },

  doSendNotification: (order) => async (dispatch) => {
    try {
      dispatch({
        type: actions.SEND_NOTIFICATION_STARTED,
      });

      await OrderService.sendNotification(order);

      dispatch({
        type: actions.SEND_NOTIFICATION_SUCCESS
      })

      Message.success(i18n('notification.success'));

    } catch (error) {
      Message.error(i18n('notification.error'));

      dispatch({
        type: actions.SEND_NOTIFICATION_SUCCESS
      });
    }
  },

  doOpenPort: (compartimentId, order, type) => async (dispatch) => {
    try {
      dispatch({
        type: actions.OPEN_PORT_STARTED,
      });

      const response = await OrderService.openPort(compartimentId, order, type);

      dispatch({
        type: actions.OPEN_PORT_SUCCESS
      })

      if(response.status === 200){
        Message.success(i18n('notification.success'));
      } else {
        Message.error(response.data);
      }

    } catch (error) {
      Message.error(i18n('notification.error'));

      dispatch({
        type: actions.OPEN_PORT_SUCCESS
      });
    }
  }
};

export default actions;

import list from 'modules/terminalUiRelease/list/releaseListReducers';
import form from 'modules/terminalUiRelease/form/releaseFormReducers';
import view from 'modules/terminalUiRelease/view/releaseViewReducers';
import destroy from 'modules/terminalUiRelease/destroy/releaseDestroyReducers';
import importerReducer from 'modules/terminalUiRelease/importer/releaseImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
import { connectRouter } from 'connected-react-router';
import layout from 'modules/layout/layoutReducers';
import auth from 'modules/auth/authReducers';
import dashboard from 'modules/dashboard/dashboardReducers';
import iam from 'modules/iam/iamReducers';
import auditLog from 'modules/auditLog/auditLogReducers';
import settings from 'modules/settings/settingsReducers';
import customer from 'modules/customer/customerReducers';
import product from 'modules/product/productReducers';
import order from 'modules/order/orderReducers';
import company from 'modules/company/companyReducers';
import role from 'modules/role/roleReducers';
import ebox from 'modules/ebox/eboxReducers';
import eboxModule from 'modules/eboxModule/eboxModuleReducers';
import eboxModuleCompartiment from 'modules/eboxModuleCompartiment/eboxModuleCompartimentReducers';
import cluster from 'modules/cluster/clusterReducers';
import clusterEbox from 'modules/clusterEbox/clusterEboxReducers';
import group from 'modules/group/groupReducers';
import packing from 'modules/packing/packingReducers';
import inbound from 'modules/inbound/inboundReducers';
import compartiment from 'modules/compartiment/compartimentReducers';
import compartimentLayout from 'modules/compartimentLayout/compartimentLayoutReducers';
import webhook from 'modules/webhook/webhookReducers';
import ccm from 'modules/ccm/ccmReducers';
import qrcodeTemplate from 'modules/qrcodeTemplate/qrcodeTemplateReducers';
import warehouse from 'modules/warehouse/warehouseReducers';
import building from 'modules/building/buildingReducers';
import buildingLevel1 from 'modules/buildingLevel1/buildingLevel1Reducers';
import buildingLevel2 from 'modules/buildingLevel2/buildingLevel2Reducers';
import terminalUiVersion from 'modules/terminalUiVersion/versionReducers';
import terminalUiRelease from 'modules/terminalUiRelease/releaseReducers';
import payment from 'modules/payment/paymentReducers';

import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    dashboard,
    iam,
    auditLog,
    settings,
    customer,
    product,
    order,
    payment,
    company,
    role,
    ebox,
    eboxModule,
    eboxModuleCompartiment,
    cluster,
    clusterEbox,
    group,
    packing,
    inbound,
    compartiment,
    compartimentLayout,
    webhook,
    ccm,
    qrcodeTemplate,
    warehouse,
    building,
    buildingLevel1,
    buildingLevel2,
    terminalUiVersion,
    terminalUiRelease,
  });

import authAxios from 'modules/shared/axios/authAxios';

export default class PackingService {
  static async finish(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/packing/finish/${id}`,
      body,
    );

    return response.data;
  }
  static async finishAll(ids) {
    let response = {};
    for (const id of ids) {
      response.data = await PackingService.finish(id, {});
    }
    return response.data;
  }
  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/packing`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/packing`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/packing/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/packing`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/packing/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

}

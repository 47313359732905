import list from 'modules/warehouse/list/warehouseListReducers';
import form from 'modules/warehouse/form/warehouseFormReducers';
import view from 'modules/warehouse/view/warehouseViewReducers';
import destroy from 'modules/warehouse/destroy/warehouseDestroyReducers';
import importerReducer from 'modules/warehouse/importer/warehouseImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/compartimentLayout/importer/compartimentLayoutImporterSelectors';
import CompartimentLayoutService from 'modules/compartimentLayout/compartimentLayoutService';
import fields from 'modules/compartimentLayout/importer/compartimentLayoutImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'CLUSTER_IMPORTER',
  selectors,
  CompartimentLayoutService.import,
  fields,
  i18n('entities.compartimentLayout.importer.fileName'),
);

import list from 'modules/buildingLevel2/list/buildingLevel2ListReducers';
import form from 'modules/buildingLevel2/form/buildingLevel2FormReducers';
import view from 'modules/buildingLevel2/view/buildingLevel2ViewReducers';
import destroy from 'modules/buildingLevel2/destroy/buildingLevel2DestroyReducers';
import importerReducer from 'modules/buildingLevel2/importer/buildingLevel2ImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/compartiment/importer/compartimentImporterSelectors';
import CompartimentService from 'modules/compartiment/compartimentService';
import fields from 'modules/compartiment/importer/compartimentImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'COMPARTIMENT_IMPORTER',
  selectors,
  CompartimentService.import,
  fields,
  i18n('entities.compartiment.importer.fileName'),
);

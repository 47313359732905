import listActions from 'modules/compartiment/list/compartimentListActions';
import CompartimentService from 'modules/compartiment/compartimentService';
import Errors from 'modules/shared/error/errors';
import { i18n } from 'i18n';
import { getHistory } from 'modules/store';
import Message from 'view/shared/message';
import OrderService from 'modules/order/orderService';

const prefix =
  'COMPARTIMENT_DESTROY';

const actions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  OPEN_PORT_STARTED: `${prefix}_OPEN_PORT_STARTED`,
  OPEN_PORT_SUCCESS: `${prefix}_OPEN_PORT_SUCCESS`,
  OPEN_PORT_ERROR: `${prefix}_OPEN_PORT_ERROR`,


  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: actions.DESTROY_STARTED,
      });

      await CompartimentService.destroyAll([id]);

      dispatch({
        type: actions.DESTROY_SUCCESS,
      });

      Message.success(i18n('entities.compartiment.destroy.success'));

      getHistory().goBack();
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: actions.DESTROY_ALL_STARTED,
      });

      await CompartimentService.destroyAll(ids);

      dispatch({
        type: actions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doChangeSelected([]));
      }

      Message.success(
        i18n('entities.compartiment.destroyAll.success'),
      );

      getHistory().goBack();
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.DESTROY_ALL_ERROR,
      });
    }
  },

  doOpenPort: (compartimentId, order, type) => async (dispatch) => {
    try {
      dispatch({
        type: actions.OPEN_PORT_STARTED,
      });

      const response = await OrderService.openPort(compartimentId, order, type);

      dispatch({
        type: actions.OPEN_PORT_SUCCESS
      })

      if (response.status === 200) {
        Message.success(i18n('openport.success'));
      } else {
        Message.error(response.data);
      }

    } catch (error) {
      Message.error(i18n('openport.error'));

      dispatch({
        type: actions.OPEN_PORT_SUCCESS
      });
    }
  }
};

export default actions;

import list from 'modules/eboxModule/list/eboxModuleListReducers';
import form from 'modules/eboxModule/form/eboxModuleFormReducers';
import view from 'modules/eboxModule/view/eboxModuleViewReducers';
import destroy from 'modules/eboxModule/destroy/eboxModuleDestroyReducers';
import importerReducer from 'modules/eboxModule/importer/eboxModuleImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

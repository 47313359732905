import model from 'modules/order/orderModel';

const { fields } = model;

export default [
  fields.id,
  fields.trackingCode,
  fields.referenceNumber,
  fields.status,
  fields.type,
  fields.company,
  fields.ebox,
  fields.toCustomer,
  fields.compartimentNumberSize,
  fields.createdAt,
  fields.inboundAt,
  fields.inboundBy,
  fields.storedAt,
  fields.storedBy,
  fields.collectedAt,
  fields.collectedBy,
  fields.overdueDate,
  fields.updatedAt
];

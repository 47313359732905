import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/buildingLevel1/importer/buildingLevel1ImporterSelectors';
import BuildingLevel1Service from 'modules/buildingLevel1/buildingLevel1Service';
import fields from 'modules/buildingLevel1/importer/buildingLevel1ImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'BUILDINGLEVEL1_IMPORTER',
  selectors,
  BuildingLevel1Service.import,
  fields,
  i18n('entities.buildingLevel1.importer.fileName'),
);

import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';

function label(name) {
  return i18n(`entities.packing.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.packing.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  order: new RelationToOneField('order', label('order'), {}),
  orders: new RelationToManyField('orders', label('orders'), {}),
  eboxes: new RelationToManyField('eboxes', label('eboxes'), {}),
  totalOrders: new StringField('totalOrders', label('totalOrders')),
  company: new RelationToOneField('company', label('company'), {}),
  packingOrders: new RelationToManyField('packingOrders', label('packingOrders'), {
    required: false,
  }),
  picking: new EnumeratorField('picking', label('picking'), [
    {
      id: 'collect/delivery',
      label: enumeratorLabel('picking', 'collect/delivery'),
    },
    {
      id: 'return',
      label: enumeratorLabel('picking', 'return'),
    }
  ]),
  notes: new StringField('notes', label('notes'), {}),
  trackingCode: new StringField('trackingCode', label('trackingCode'), {}),
  ebox: new RelationToOneField('ebox', label('ebox'), {}),
  operator: new RelationToOneField(
    'operator',
    label('operator'),
    {},
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  createdBy: new RelationToOneField(
    'createdBy',
    label('createdBy'),
    {},
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  finishedAt: new DateTimeField(
    'finishedAt',
    label('finishedAt'),
  ),
  finishedBy: new RelationToOneField(
    'finishedBy',
    label('finishedBy'),
    {},
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  steps: [
    {
      title: 'Coleta Reversa',
      name: 'collect_return',
      orderStatusArray: ['STORED'],
      orderTypeArray: ['RETURN', 'SEND'],
      orderRowsArray: new RelationToManyField('orders', label('orders'), {}),
    },
    {
      title: 'Coleta Expirados',
      name: 'collect_expired',
      orderStatusArray: ['EXPIRED'],
      orderTypeArray: ['DELIVERY'],
      orderRowsArray: new RelationToManyField('orders', label('orders'), {})
    },
    {
      title: 'Entrega pedidos',
      name: 'delivery',
      orderStatusArray: ['INBOUND'],
      orderTypeArray: ['DELIVERY'],
      orderRowsArray: new RelationToManyField('orders', label('orders'), {})
    },
  ]
};

export default {
  fields,
};

import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import IntegerField from 'modules/shared/fields/integerField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import BooleanField from 'modules/shared/fields/booleanField';
import DecimalField from 'modules/shared/fields/decimalField';

function label(name) {
  return i18n(`entities.eboxModuleCompartiment.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  compartimentLayout: new RelationToOneField('compartimentLayout', label('compartimentLayout'), {}),
  eboxModule: new RelationToOneField('eboxModule', label('eboxModule'), {}),
  staticNumber: new IntegerField('staticNumber', label('staticNumber'), {}),
  pcd: new BooleanField('pcd', label('pcd')),
  enable: new BooleanField('enable', label('enable')),
  priority: new DecimalField('priority', label('priority'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export default {
  fields,
};

import list from 'modules/webhook/list/webhookListReducers';
import form from 'modules/webhook/form/webhookFormReducers';
import view from 'modules/webhook/view/webhookViewReducers';
import destroy from 'modules/webhook/destroy/webhookDestroyReducers';
import importerReducer from 'modules/webhook/importer/webhookImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

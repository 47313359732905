import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import BooleanField from 'modules/shared/fields/booleanField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import ImagesField from 'modules/shared/fields/imagesField';

function label(name) {
  return i18n(`entities.ebox.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.ebox.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {}),
  code: new StringField('code', label('code'), {}),
  images: new ImagesField('images', label('images'), 'ebox/images', {}),
  enable: new BooleanField('enable', label('enable')),
  status: new EnumeratorField('status', label('status'), [
    { id: 'online', label: enumeratorLabel('status', 'online') },
    { id: 'offline', label: enumeratorLabel('status', 'offline') },
  ], {}),
  shared: new BooleanField('shared', label('shared')),
  workingHours: new StringField('workingHours', label('workingHours'), {}),
  osVersion: new RelationToOneField('osVersion', label('osVersion'), {}),
  application: new StringField('application', label('application'), {}),
  applicationVersion: new RelationToOneField('terminalUiVersions', label('applicationVersion'), {}),
  street: new StringField('street', label('street'), {}),
  number: new StringField('number', label('number'), {}),
  complement: new StringField('complement', label('complement'), {}),
  terminalId: new StringField('terminalId', label('terminalId'), {}),
  neighborhood: new StringField('neighborhood', label('neighborhood'), {}),
  city: new StringField('city', label('city'), {}),
  lastHeartBeat: new DateTimeField(
    'lastHeartBeat',
    label('lastHeartBeat'),
  ),
  state: new StringField('state', label('state'), {}),
  postalCode: new StringField('postalCode', label('postalCode'), {}),
  coordinates: new StringField('coordinates', label('coordinates'), {}),
  warehouse: new RelationToOneField('warehouse', label('warehouse'), {}),
  company: new RelationToOneField('company', label('company'), {}),
  building: new RelationToOneField('building', label('building'), {}),
  eboxModule: new RelationToManyField(
    'eboxModule',
    label('eboxModule'),
    {},
  ),
  currentModules: new RelationToManyField(
    'currentModules',
    label('eboxModule'),
    {},
  ),
  boardNumber: new EnumeratorField('boardNumber', label('boardNumber'), [
    { key: '1', label: '1' },
    { key: '2', label: '2' },
    { key: '3', label: '3' },
    { key: '4', label: '4' },
    { key: '5', label: '5' },
    { key: '6', label: '6' },
    { key: '7', label: '7' },
    { key: '8', label: '8' },
    { key: '9', label: '9' },
    { key: '10', label: '10' }
  ], {}),
  startNumber: new StringField('startNumber', label('startNumber'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export default {
  fields,
};

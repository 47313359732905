import list from 'modules/inbound/list/inboundListReducers';
import form from 'modules/inbound/form/inboundFormReducers';
import view from 'modules/inbound/view/inboundViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view
});

import { createSelector } from 'reselect';

const selectRaw = (state, key, step) => {
  return state.order.packing.find(e => e.key === key && e.step === step) || {};
};

const selectState = (state) => {
  return state.order.packing;
};

const selectLoading = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => raw.loading,
);

const selectRows = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => raw.rows,
);

const selectCount = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => raw.count,
);

const selectHasRows = createSelector(
  [(key) => key, (step) => step, selectCount],
  (key, count) => count > 0,
);

const selectOrderBy = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => {
    const sorter = raw.sorter;

    if (!sorter) {
      return null;
    }

    if (!sorter.columnKey) {
      return null;
    }

    let direction =
      sorter.order === 'descend' ? 'DESC' : 'ASC';

    return `${sorter.columnKey}_${direction}`;
  },
);

const selectFilter = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => {
    return raw.filter;
  },
);

const selectLimit = createSelector([selectRaw, (key) => key, (step) => step], (raw, key, step) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => {
    const pagination = raw.pagination;

    if (!pagination || !pagination.pageSize) {
      return 0;
    }

    const current = pagination.current || 1;

    return (current - 1) * pagination.pageSize;
  },
);

const selectPagination = createSelector(
  [selectRaw, (key) => key, (step) => step, selectCount],
  (raw, key, step, count) => {
    return {
      ...raw.pagination,
      total: count,
      defaultPageSize: 25,
      showSizeChanger: true,
      pageSizeOptions: ['10', '25', '50', '100', '200']
    };
  },
);

const selectSelectedKeys = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => {
    return raw.selectedKeys;
  },
);
const selectedRowsGenerate = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => {
    return raw.selectedRows;
  },
);

const selectSelectedRows = createSelector(
  [selectRaw, (key) => key, (step) => step, selectRows],
  (raw, key, step, rows) => {
    return rows.filter((row) =>
      raw.selectedKeys.includes(row.id),
    );
  },
);

const selectPacking = createSelector(
  [selectState],
  (packing) => {
    return packing;
  }
)

const selectMetrics = createSelector(
  [selectRaw, (key) => key, (step) => step],
  (raw, key, step) => {
    return raw.metrics;
  }
)

export default {
  selectLoading,
  selectRows,
  selectCount,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectSelectedKeys,
  selectedRowsGenerate,
  selectSelectedRows,
  selectHasRows,
  selectPacking,
  selectMetrics
};

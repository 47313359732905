import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/webhook/importer/webhookImporterSelectors';
import WebhookService from 'modules/webhook/webhookService';
import fields from 'modules/webhook/importer/webhookImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'WEBHOOK_IMPORTER',
  selectors,
  WebhookService.import,
  fields,
  i18n('entities.webhook.importer.fileName'),
);

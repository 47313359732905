import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import BooleanField from 'modules/shared/fields/booleanField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';

function label(name) {
  return i18n(`entities.qrcodeTemplate.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.qrcodeTemplate.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      { id: 'STORED', label: enumeratorLabel('type', 'STORED') },
      {
        id: 'CUSTOMER_TAKEN',
        label: enumeratorLabel('type', 'CUSTOMER_TAKEN'),
      },
      {
        id: 'CUSTOMER_SEND',
        label: enumeratorLabel('type', 'CUSTOMER_SEND'),
      },
      {
        id: 'EXPIRED',
        label: enumeratorLabel('type', 'EXPIRED'),
      },
    ],
    {
      required: true,
    },
  ),
  hbsContent: new StringField('hbsContent', label('hbsContent'), {
    required: true,
  }),
  company: new RelationToOneField(
    'company',
    label('company'),
    {
      required: true,
    },
  ),
  enable: new BooleanField('enable', label('enable')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export default {
  fields,
};

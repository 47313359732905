import model from 'modules/eboxModule/eboxModuleModel';

const { fields } = model;

export default [
  fields.name,
  fields.code,
  fields.eboxModuleCompartiment,
  fields.enable,
];

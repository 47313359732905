import model from 'modules/compartimentLayout/compartimentLayoutModel';

const { fields } = model;

export default [
  fields.code,
  fields.name,
  fields.width,
  fields.height,
  fields.depth,
  fields.enable,
];

import model from 'modules/clusterEbox/clusterEboxModel';

const { fields } = model;

export default [
  fields.clusterName,
  fields.eboxCode,
  fields.clusterPosition,
  fields.enable,
];

import actions from 'modules/inbound/form/inboundFormActions';

const INITIAL_PAGE_SIZE = 1000;

const initialData = {
  rows: [],
  count: 0,
  loading: false,
  saveLoading: false,
  filter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
    limit: 1
  },
  sorter: {},
  selectedKeys: [],
  selectedRows: [],
  notFoundOrders: []
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.RESETED) {
    return {
      ...initialData,
    };
  }

  if (type === actions.SELECTEDS_CHANGED) {
    return {
      ...state,
      selectedKeys: payload.selectedRowKeys || [],
      selectedRows: payload.selectedRows || [],
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      selectedRows: [],
      filter: payload ? payload.filter : {},
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
            current: 1,
            pageSize: INITIAL_PAGE_SIZE,
            limit: 1
          },
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    if (state.filter && state.filter.trackingCode && payload.rows.length === 0) {
      return {
        ...state,
        loading: false,
        notFoundOrders: [...state.notFoundOrders, state.filter.trackingCode],
      };
    }
    return {
      ...state,
      loading: false,
      rows: state.rows.concat(
        payload.rows.filter(
          (newItem) => !state.rows.some((existingItem) => existingItem.id === newItem.id)
        )
      ),
      count: state.count + payload.rows.filter(
        (newItem) => !state.rows.some((existingItem) => existingItem.id === newItem.id)
      ).length,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.CREATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.CREATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.CREATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }


  return state;
};

import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import BooleanField from 'modules/shared/fields/booleanField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';

function label(name) {
  return i18n(`entities.ccm.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.ccm.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      { id: 'sms', label: enumeratorLabel('type', 'sms') },
      {
        id: 'email',
        label: enumeratorLabel('type', 'email'),
      },
      {
        id: 'push',
        label: enumeratorLabel('type', 'push'),
      },
    ],
    {
      required: true,
    },
  ),
  subType: new EnumeratorField(
    'subType',
    label('subType'),
    [
      {
        id: 'DELIVERY',
        label: enumeratorLabel('subType', 'delivery'),
      },
      {
        id: 'RETURN',
        label: enumeratorLabel('subType', 'return'),
      },
      {
        id: 'STORAGE',
        label: enumeratorLabel('subType', 'storage'),
      },
      {
        id: 'SEND',
        label: enumeratorLabel('subType', 'send'),
      }
    ],
    {
      required: true,
    },
  ),
  content: new StringField('content', label('content'), {
    required: true,
  }),
  cluster: new RelationToOneField('cluster', label('cluster'), {}),
  subject: new StringField('subject', label('subject'), {}),
  trigger: new EnumeratorField(
    'trigger',
    label('trigger'),
    [
      {
        id: 'CREATED',
        label: enumeratorLabel('trigger', 'CREATED'),
      },
      {
        id: 'INBOUND',
        label: enumeratorLabel('trigger', 'INBOUND'),
      },
      {
        id: 'IN_TRANSIT_TO_STORE',
        label: enumeratorLabel('trigger', 'IN_TRANSIT_TO_STORE'),
      },
      {
        id: 'STORED',
        label: enumeratorLabel('trigger', 'STORED'),
      },
      {
        id: 'CUSTOMER_TAKEN',
        label: enumeratorLabel('trigger', 'CUSTOMER_TAKEN'),
      },
      {
        id: 'EXPIRED',
        label: enumeratorLabel('trigger', 'EXPIRED'),
      },
      {
        id: 'EXPIRED_TAKEN',
        label: enumeratorLabel(
          'trigger',
          'EXPIRED_TAKEN',
        ),
      },
      {
        id: 'EXPIRED_INBOUND',
        label: enumeratorLabel(
          'trigger',
          'EXPIRED_INBOUND',
        ),
      },
      {
        id: 'EXPIRED_RETURNED',
        label: enumeratorLabel(
          'trigger',
          'EXPIRED_RETURNED',
        ),
      },
      {
        id: 'COURIER_TAKEN',
        label: enumeratorLabel(
          'trigger',
          'COURIER_TAKEN',
        ),
      },
      {
        id: 'COURIER_INBOUND',
        label: enumeratorLabel(
          'trigger',
          'COURIER_INBOUND',
        ),
      },
      {
        id: 'COURIER_RETURNED',
        label: enumeratorLabel(
          'trigger',
          'COURIER_RETURNED',
        ),
      },
      {
        id: 'PRE_EXPIRED',
        label: enumeratorLabel(
          'trigger',
          'PRE_EXPIRED',
        ),
      },
      {
        id: 'CANCELED',
        label: enumeratorLabel(
          'trigger',
          'CANCELED',
        ),
      },
    ],
    {
      required: true,
    },
  ),
  company: new RelationToOneField(
    'company',
    label('company'),
    {
      required: true,
    },
  ),
  enable: new BooleanField('enable', label('enable')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export default {
  fields,
};

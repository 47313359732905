import model from 'modules/order/orderModel';

const { fields } = model;

export default [
  fields.type,
  fields.trackingCode,
  fields.referenceNumber,
  fields.eboxCode,
  fields.customerName,
  fields.customerPhone,
  fields.customerEmail,
  fields.overdueDate,
  fields.compartimentSize,
  fields.notBeforeFirst,
  fields.bookOrder,
];

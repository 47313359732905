import model from 'modules/ccm/ccmModel';

const { fields } = model;

export default [
  fields.type,
  fields.content,
  fields.subType,
  fields.subject,
  fields.trigger,
  fields.company,
  fields.enable,
];

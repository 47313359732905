import list from 'modules/role/list/roleListReducers';
import form from 'modules/role/form/roleFormReducers';
import view from 'modules/role/view/roleViewReducers';
import destroy from 'modules/role/destroy/roleDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy
});

import model from 'modules/company/companyModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.logo,
  fields.type,
  fields.externalId,
  fields.allowCollectExpiredOrder,
  fields.attachments,
  fields.parent,
  fields.building,
  fields.createdAt,
  fields.updatedAt
];

import OrderService from 'modules/order/orderService';
import EboxService from 'modules/ebox/eboxService';
import selectors from 'modules/order/packing/orderPackingSelectors';
import Errors from 'modules/shared/error/errors';
import { getHistory } from 'modules/store';

const prefix = 'ORDER_PACKING';

const actions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,
  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  CHANGE_COMPARTIMENT_SIZE: `${prefix}_CHANGE_COMPARTIMENT_SIZE`,
  ADD_EBOX: `${prefix}_ADD_EBOX`,
  METRICS_FIND: `${prefix}_METRICS_FIND`,
  METRICS_SUCCESS: `${prefix}_METRICS_SUCCESS`,
  METRICS_ERROR: `${prefix}_METRICS_ERROR`,

  doChangeSelected(key, step, payload) {
    return {
      type: actions.SELECTEDS_CHANGED,
      payload,
      key,
      step
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: actions.RESETED,
    });
  },

  doChangePaginationAndSort: (
    key,
    step,
    pagination,
    sorter,
  ) => async (dispatch, getState) => {

    const filter = selectors.selectFilter(getState(), key, step);

    dispatch({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
      key,
      step
    });

    dispatch({
      type: actions.SORTER_CHANGED,
      payload: sorter,
      key,
      step
    });


    dispatch(actions.doFetch(key, step, filter, true));
  },

  doChangeCompartimentSize: (
    key,
    step,
    record,
    value,
  ) => async (dispatch) => {
    dispatch({
      type: actions.CHANGE_COMPARTIMENT_SIZE,
      payload: { record, value },
      key,
      step
    });
  },

  doMetrics: (key, step) => async (dispatch) => {
    try {
      dispatch({
        type: actions.METRICS_FIND,
        key,
        step
      });

      const record = await EboxService.getMetrics(key);

      dispatch({
        type: actions.METRICS_SUCCESS,
        payload: record,
        key,
        step
      });
    } catch (err) {
      Errors.handle(err);

      dispatch({
        type: actions.METRICS_ERROR,
        key,
        step
      });

      getHistory().push('/packing/new');
    }
  },

  doAddPacking: (key, step) => async (dispatch, getState) => {
    dispatch({
      type: actions.ADD_EBOX,
      key,
      step
    });
  },

  doFetch: (key, step, filter, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: actions.FETCH_STARTED,
        payload: { filter, keepPagination },
        key,
        step
      });

      const response = await OrderService.list(
        filter,
        selectors.selectOrderBy(getState(), key, step),
        selectors.selectLimit(getState(), key, step),
        selectors.selectOffset(getState(), key, step),
      );

      dispatch({
        type: actions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
        key,
        step
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FETCH_ERROR,
        key,
        step
      });
    }
  },

};

export default actions;

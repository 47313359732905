import model from 'modules/payment/paymentModel';

const { fields } = model;

export default [
  fields.id,
  fields.gateway,
  fields.value,
  fields.ebox,
  fields.cluster,
  fields.status,
  fields.overdueDate,
  fields.trackingCode,
  fields.compartimentNumber,
  fields.compartimentSize,
  fields.company,  
  fields.createdAt,
];

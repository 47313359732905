import model from 'modules/packing/packingModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.pickimg,
  fields.orders,
  fields.warehouse,
  fields.createdAt,
  fields.updatedAt
];

import model from 'modules/warehouse/warehouseModel';

const { fields } = model;

export default [
  fields.id,
  fields.company,
  fields.street,
  fields.name,
  fields.createdAt,
  fields.updatedAt
];

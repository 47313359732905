import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';

import EnumeratorField from 'modules/shared/fields/enumeratorField';

function label(name) {
  return i18n(`entities.release.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.release.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'CREATED',
      label: enumeratorLabel('status', 'CREATED'),
    },
    {
      id: 'SCHEDULED',
      label: enumeratorLabel('status', 'SCHEDULED'),
    },
    {
      id: 'CUSTOMER_TAKEN',
      label: enumeratorLabel('status', 'CUSTOMER_TAKEN'),
    },
    {
      id: 'EXPIRED',
      label: enumeratorLabel('status', 'EXPIRED'),
    },
    {
      id: 'EXPIRED_TAKEN',
      label: enumeratorLabel('status', 'EXPIRED_TAKEN'),
    },
    {
      id: 'CUSTOMER_STORED',
      label: enumeratorLabel('status', 'CUSTOMER_STORED'),
    },
    {
      id: 'COURIER_STORED',
      label: enumeratorLabel('status', 'COURIER_STORED'),
    },
    {
      id: 'STORED',
      label: enumeratorLabel('status', 'STORED'),
    },
    {
      id: 'CANCELED',
      label: enumeratorLabel('status', 'CANCELED'),
    },
  ]),
  description: new StringField('description', label('description'), {}),
  ebox: new RelationToManyField('ebox', label('ebox'), {}),
  company: new RelationToOneField(
    'company',
    label('company'),
    {
      required: true,
    },
  ),
  terminalUiVersion: new RelationToManyField('terminalUiVersions', label('terminalUiVersion')),
  scheduledAt: new DateTimeField(
    'scheduledAt',
    label('scheduledAt'),
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export default {
  fields,
};

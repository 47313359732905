import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import IntegerField from 'modules/shared/fields/integerField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import BooleanField from 'modules/shared/fields/booleanField';
import StringField from 'modules/shared/fields/stringField';

function label(name) {
  return i18n(`entities.clusterEbox.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.clusterEbox.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  cluster: new RelationToOneField('cluster', label('cluster'), {}),
  ebox: new RelationToOneField('ebox', label('ebox'), {}),
  sequenceCompartiments: new EnumeratorField('sequenceCompartiments', label('sequenceCompartiments'), [
    { id: 'RL-TB', label: enumeratorLabel('sequenceCompartiments', 'RL-TB') },
    { id: 'RL-BT', label: enumeratorLabel('sequenceCompartiments', 'RL-BT') },
    { id: 'LR-TB', label: enumeratorLabel('sequenceCompartiments', 'LR-TB') },
    { id: 'LR-BT', label: enumeratorLabel('sequenceCompartiments', 'LR-BT') }
  ]),
  clusterPosition: new IntegerField('clusterPosition', label('clusterPosition'), {}),
  enable: new BooleanField('enable', label('enable')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  clusterName: new StringField('clusterName', label('clusterName'), {}),
  eboxCode: new StringField('eboxCode', label('eboxCode'), {}),
};

export default {
  fields,
};

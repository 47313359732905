import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/warehouse/importer/warehouseImporterSelectors';
import WarehouseService from 'modules/warehouse/warehouseService';
import fields from 'modules/warehouse/importer/warehouseImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'WAREHOUSE_IMPORTER',
  selectors,
  WarehouseService.import,
  fields,
  i18n('entities.warehouse.importer.fileName'),
);

import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/building/importer/buildingImporterSelectors';
import BuildingService from 'modules/building/buildingService';
import fields from 'modules/building/importer/buildingImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'BUILDING_IMPORTER',
  selectors,
  BuildingService.import,
  fields,
  i18n('entities.building.importer.fileName'),
);

import GenericField from 'modules/shared/fields/genericField';
import * as yup from 'yup';

export default class RelationToOneField extends GenericField {
  constructor(name, label, { required = false } = {}) {
    super(name, label);

    this.required = required;
  }

  forTable(overrides) {
    const defaultRender = undefined;

    const {
      title = this.label,
      sorter = false,
      dataIndex = this.name,
      render = defaultRender,
    } = overrides || {};

    return {
      title,
      sorter,
      dataIndex,
      render,
    };
  }

  forView(value) {
    return value;
  }

  forFormInitialValue(value) {
    return value;
  }

  forForm() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        return originalValue.id;
      });

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }

  forFilter() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        return originalValue.id;
      });
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.id) {
          return null;
        }
        if (this.name === "toCustomer")
          return originalValue.name;
        if (this.name === "ebox")
          return originalValue.code || originalValue.name;
        if (this.name === "inboundBy")
          return originalValue.firstName;
        if (this.name === "storedBy")
          return originalValue.firstName;
        if (this.name === "compartiment")
          return `${originalValue.number} - ${originalValue.size}`;
        if (this.name === "cluster")
          return originalValue.name;

        if (this.name === "ebox")
          return `${originalValue.name} (${originalValue.code})`;
        if (["inboundBy", "storedBy", "collectedBy", "createdBy"].includes(this.name))
          return originalValue.firstName;
        if (this.name === "compartiment")
          return `${originalValue.number} - ${originalValue.size}`;

        return originalValue.name || originalValue.id;
      });
  }

  forImport() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }
}

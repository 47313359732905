import model from 'modules/qrcodeTemplate/qrcodeTemplateModel';

const { fields } = model;

export default [
  fields.type,
  fields.hbsContent,
  fields.company,
  fields.enable,
];

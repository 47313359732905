import authAxios from 'modules/shared/axios/authAxios';

export default class OrderService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/order/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/order`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/order`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/order/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/order/${id}`);
    return response.data;
  }

  static async findByTrackingCode(trackingCode) {
    const response = await authAxios.get(`/order?filter[trackingCode]=${trackingCode}`);
    return response.data;
  }

  static async ordersExport(filter) {
    let params = {
      filter,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const response = await authAxios.get(`/order/export`, {
      params,
    });

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    let params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/order`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/order/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async sendNotification(order) {
    const response = await authAxios.post(`/order/send-notification`, {
      ...order
    });

    return response.data;
  }

  static async updateOverdue(order) {
    const response = await authAxios.post(`/order/update-overdue`, {
      ...order
    });

    return response.data;
  }

  static async generateTag(dataTags) {
    const response = await authAxios.post(`/order/generate-tag`, {
      data: dataTags,
      type: 'DELIVERY'
    }
    );
    return response.data
  }

  static async openPort(compartimentId, orderId, type) {
    console.log('data openPOrt', compartimentId, orderId, type)

    let url = ''
    if (type === 'order') {
      url = `/compartiment/open/${compartimentId}?orderId=${orderId}`
    } else {
      url = `/compartiment/open/${compartimentId}`
    }

    try {
      const response = await authAxios.get(url);
      return response
    } catch (error) {
      return error.response
    }
  }
}

import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import RelationToManyField from 'modules/shared/fields/relationToManyField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';

function label(name) {
  return i18n(`entities.inbound.fields.${name}`);
}


const fields = {
  id: new IdField('id', label('id')),
  order: new RelationToOneField('order', label('order'), {}),
  orders: new RelationToManyField('orders', label('orders'), {}),
  totalOrders: new StringField('totalOrders', label('totalOrders')),
  inboundOrders: new RelationToManyField('packingOrders', label('packingOrders'), {
    required: false,
  }),
  orderscompany: new RelationToManyField('orderscompany', label('orderscompany'), {
    required: false,
  }),
  notes: new StringField('notes', label('notes'), {}),
  trackingCode: new StringField('trackingCode', label('trackingCode'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  createdBy: new RelationToOneField(
    'createdBy',
    label('createdBy'),
    {},
  ),
  company: new RelationToOneField('company', label('company'), {}),
  inboundAt: new DateTimeField(
    'inboundAt',
    label('inboundAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  deliveryWindow: new DateTimeField(
    'deliveryWindow',
    label('deliveryWindow'),
  ),
};

export default {
  fields,
};

import authAxios from 'modules/shared/axios/authAxios';

export default class EboxService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/ebox/${id}`,
      body,
    );

    return response.data;
  }

  static async clone(id, data) {
    const response = await authAxios.put(
      `/ebox/clone/${id}`,
      data,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/ebox`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/ebox`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/ebox/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/ebox/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/ebox`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit, clusterId = null, packingOrders = false) {
    let url = `/ebox/autocomplete`;
    let params = {
      packingOrders,
      query,
      limit
    };

    if (clusterId) {
      url = `/clusterEbox/autocompleteEbox`;
      params = {
        ...params,
        clusterId
      };
    }

    const response = await authAxios.get(
      url,
      {
        params,
      },
    );

    return response.data;
  }

  static async generateToken(id, config) {
    const body = {
      locker: id,
      config
    }

    const response = await authAxios.post('/ebox/createToken', body);

    return response.data
  }

  static async getMetrics(id) {
    const response = await authAxios.get('/ebox/metrics', {
      params: {
        eboxId: id
      }
    });

    return response.data
  }

  static async generateCompartiments(data) {
    let body = {
      data: data
    }

    const response = await authAxios.post('/ebox/generateCompartiments', body);

    return response.data
  }

  static async terminalCommander(data) {
    const response = await authAxios.post('/ebox/terminal/send', data);
    return response.data
  }
}

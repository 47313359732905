import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import StringField from 'modules/shared/fields/stringField';
import BooleanField from 'modules/shared/fields/booleanField';
import DecimalField from 'modules/shared/fields/decimalField';

function label(name) {
  return i18n(`entities.compartimentLayout.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  code: new StringField('code', label('code'), {
    "required": true
  }),
  name: new StringField('name', label('name'), {
    "required": true
  }),
  width: new DecimalField('width', label('width'), {
    "required": true
  }),
  height: new DecimalField('height', label('height'), {
    "required": true
  }),
  depth: new DecimalField('depth', label('depth'), {
    "required": true
  }),
  enable: new BooleanField('enable', label('enable')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export default {
  fields,
};

import authAxios from 'modules/shared/axios/authAxios';

export default class DashboardService {
  static async fetch(filter) {

    const response = await authAxios.get('/dashboard', {
      params: {
        filter
      },
    });

    return response.data;
  }
}

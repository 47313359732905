import model from 'modules/eboxModuleCompartiment/eboxModuleCompartimentModel';

const { fields } = model;

export default [
  fields.compartimentLayout,
  fields.eboxModule,
  fields.staticNumber,
  fields.pcd,
  fields.enable,
];

import list from 'modules/cluster/list/clusterListReducers';
import form from 'modules/cluster/form/clusterFormReducers';
import view from 'modules/cluster/view/clusterViewReducers';
import destroy from 'modules/cluster/destroy/clusterDestroyReducers';
import importerReducer from 'modules/cluster/importer/clusterImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

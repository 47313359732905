import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/ebox/importer/eboxImporterSelectors';
import EboxService from 'modules/ebox/eboxService';
import fields from 'modules/ebox/importer/eboxImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'EBOX_IMPORTER',
  selectors,
  EboxService.import,
  fields,
  i18n('entities.ebox.importer.fileName'),
);

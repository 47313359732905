import { i18n } from 'i18n';
import IdField from 'modules/shared/fields/idField';
import DateTimeField from 'modules/shared/fields/dateTimeField';
import DateTimeRangeField from 'modules/shared/fields/dateTimeRangeField';
import IntegerField from 'modules/shared/fields/integerField';
import IntegerRangeField from 'modules/shared/fields/integerRangeField';
import EnumeratorField from 'modules/shared/fields/enumeratorField';
import BooleanField from 'modules/shared/fields/booleanField';
import RelationToOneField from 'modules/shared/fields/relationToOneField';
import StringField from 'modules/shared/fields/stringField';

function label(name) {
  return i18n(`entities.compartiment.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.compartiment.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  number: new IntegerField('number', label('number'), {
    "required": true
  }),
  boardNumber: new IntegerField('boardNumber', label('boardNumber'), {
    "required": true
  }),
  comNumber: new StringField('comNumber', label('comNumber'), {
    "required": true
  }),
  numberInColumn: new IntegerField('numberInColumn', label('numberInColumn'), {
    "required": true
  }),
  usageType: new EnumeratorField('usageType', label('usageType'), [
    { id: 'DELIVERY', label: enumeratorLabel('usageType', 'DELIVERY') },
    { id: 'LAUNDRY', label: enumeratorLabel('usageType', 'LAUNDRY') },
    { id: 'FOOD', label: enumeratorLabel('usageType', 'FOOD') },
    { id: 'DROP', label: enumeratorLabel('usageType', 'DROP') }
  ]),
  size: new StringField('size', label('size'), {
    "required": true
  }),
  ebox: new RelationToOneField('ebox', label('ebox'), {
    "required": true
  }),
  status: new EnumeratorField('status', label('status'), [
    { id: 'AVAILABLE', label: enumeratorLabel('status', 'AVAILABLE') },
    { id: 'OCCUPIED', label: enumeratorLabel('status', 'OCCUPIED') },
    { id: 'DAMAGE', label: enumeratorLabel('status', 'DAMAGE') },
    { id: 'DIRTY', label: enumeratorLabel('status', 'DIRTY') },
    { id: 'BOOKED', label: enumeratorLabel('status', 'BOOKED') },
  ], {}),
  width: new StringField('width', label('width'), {
    "required": true
  }),
  height: new StringField('height', label('height'), {
    "required": true
  }),
  depth: new StringField('depth', label('depth'), {
    "required": true
  }),
  pcd: new BooleanField('pcd', label('pcd')),
  enable: new BooleanField('enable', label('enable')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  numberRange: new IntegerRangeField(
    'numberRange',
    label('numberRange'),
  ),
  boardNumberRange: new IntegerRangeField(
    'boardNumberRange',
    label('boardNumberRange'),
  ),
};

export default {
  fields,
};

import list from 'modules/buildingLevel1/list/buildingLevel1ListReducers';
import form from 'modules/buildingLevel1/form/buildingLevel1FormReducers';
import view from 'modules/buildingLevel1/view/buildingLevel1ViewReducers';
import destroy from 'modules/buildingLevel1/destroy/buildingLevel1DestroyReducers';
import importerReducer from 'modules/buildingLevel1/importer/buildingLevel1ImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

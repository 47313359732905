import list from 'modules/ebox/list/eboxListReducers';
import form from 'modules/ebox/form/eboxFormReducers';
import view from 'modules/ebox/view/eboxViewReducers';
import destroy from 'modules/ebox/destroy/eboxDestroyReducers';
import generateToken from 'modules/ebox/generateToken/eboxGenerateTokenReducers';
import importerReducer from 'modules/ebox/importer/eboxImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  generateToken,
  importer: importerReducer,
});

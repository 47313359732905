import authAxios from 'modules/shared/axios/authAxios';
import Axios from 'axios';

export default class VersionService {
  static async update(id, data) {

    const body = {
      id,
      data: {
        identification: data.identification,
        description: data.description,
        company: data.company
      },
    };

    const response = await authAxios.put(
      `/terminalUiVersion/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/terminalUiVersion`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    console.log(data);

    const body = {
      contentType: data.fileList[0].type,
      fileName: data.fileList[0].name,
      type:"terminalUiVersion"
    };

    const response = await authAxios.post(
      `/upload/terminalUiVersion`,
      body,
    );

    if(response.status === 200){
      const responseAWS = await Axios.put(
        response.data.SignedUrl,
        data.fileList[0],
        {
          headers: {
            'Content-Type': data.fileList[0].type,
            'Content-Encoding': 'base64'
          }
        }
      )
      console.log(responseAWS.status);
      
      if(responseAWS.status === 200){

        const dataCreate = {
          identification: data.identification,
          description: data.description,
          company: data.company,
          keyS3: response.data.Key,
          file: response.data.File.id,
        }
        console.log(dataCreate);

        const responseCreate = await authAxios.post(
          `/terminalUiVersion`,
          { data: dataCreate },
        );

        console.log(responseCreate)

        return responseCreate.data;
      }

    }
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/terminalUiVersion/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/terminalUiVersion/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get(`/terminalUiVersion`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/terminalUiVersion/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
  
  static async listCascader(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/terminalUiVersion/cascader`,
      {
        params,
      },
    );

    return response.data;
  }
}

import list from 'modules/packing/list/packingListReducers';
import form from 'modules/packing/form/packingFormReducers';
import view from 'modules/packing/view/packingViewReducers';
import destroy from 'modules/packing/destroy/packingDestroyReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy
});

import list from 'modules/compartiment/list/compartimentListReducers';
import form from 'modules/compartiment/form/compartimentFormReducers';
import view from 'modules/compartiment/view/compartimentViewReducers';
import destroy from 'modules/compartiment/destroy/compartimentDestroyReducers';
import importerReducer from 'modules/compartiment/importer/compartimentImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

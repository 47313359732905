import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/clusterEbox/importer/clusterEboxImporterSelectors';
import ClusterEboxService from 'modules/clusterEbox/clusterEboxService';
import fields from 'modules/clusterEbox/importer/clusterEboxImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'CLUSTER_IMPORTER',
  selectors,
  ClusterEboxService.import,
  fields,
  i18n('entities.clusterEbox.importer.fileName'),
);

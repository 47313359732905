import list from 'modules/qrcodeTemplate/list/qrcodeTemplateListReducers';
import form from 'modules/qrcodeTemplate/form/qrcodeTemplateFormReducers';
import view from 'modules/qrcodeTemplate/view/qrcodeTemplateViewReducers';
import destroy from 'modules/qrcodeTemplate/destroy/qrcodeTemplateDestroyReducers';
import importerReducer from 'modules/qrcodeTemplate/importer/qrcodeTemplateImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});

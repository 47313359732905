import model from 'modules/building/buildingModel';

const { fields } = model;

export default [
  fields.id,
  fields.name,
  fields.level1,
  fields.createdAt,
  fields.updatedAt
];

import importerActions from 'modules/shared/importer/importerActions';
import selectors from 'modules/eboxModule/importer/eboxModuleImporterSelectors';
import EboxModuleService from 'modules/eboxModule/eboxModuleService';
import fields from 'modules/eboxModule/importer/eboxModuleImporterFields';
import { i18n } from 'i18n';

export default importerActions(
  'CLUSTER_IMPORTER',
  selectors,
  EboxModuleService.import,
  fields,
  i18n('entities.eboxModule.importer.fileName'),
);

import Errors from 'modules/shared/error/errors';
import EboxService from 'modules/ebox/eboxService';
import Message from 'view/shared/message';

const prefix = 'EBOX_TOKEN';

const actions = {
  GENERATE: `${prefix}_GENERATE`,
  GENERATE_SUCCESS: `${prefix}_GENERATE_SUCCESS`,
  GENERATE_ERROR: `${prefix}_GENERATE_ERROR`,

  doGenerate: (id) => async (dispatch) => {
    try {
      await EboxService.generateToken(id);

      Message.success('Token Gerado com Sucesso!')

    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.GENERATE_ERROR
      })
    }
  }
};

export default actions;